import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BlockchainProvider } from './BlockchainContext';
import { ConnectKitProvider } from "connectkit";
import { ChakraProvider } from '@chakra-ui/react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

const isSepolia = window.location.hostname === 'sepolia.bets2024.com';
const the_graph_url = isSepolia
  ? "https://api.studio.thegraph.com/query/51125/decision2024-sepolia/version/latest"
  : "https://api.studio.thegraph.com/query/51125/bets2024/version/latest";


const client = new ApolloClient({
  uri: the_graph_url,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BlockchainProvider>
        <ConnectKitProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ConnectKitProvider>
      </BlockchainProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();