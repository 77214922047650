import React from 'react';
import { SimpleGrid, Box, Link } from '@chakra-ui/react';

const Footer = () => (
  <SimpleGrid width="100%" columns={2} fontSize="small" color="#1f3c60" p={4} pt={0} backgroundColor="whitesmoke">
    <Box align="left">
      ©2024
    </Box>
    <Box align="right" minWidth="150px">
      <Link href="bets2024@gmail.com">bets2024@gmail.com</Link>
    </Box>
  </SimpleGrid>
);

export default Footer;