import React from 'react';
import {
  Box, Card, CardBody, Link, OrderedList, Text, Accordion, AccordionIcon, 
  AccordionItem, AccordionButton, AccordionPanel, ListItem,
  Divider, Heading, List, ListIcon
} from '@chakra-ui/react';
import { ExternalLinkIcon, ArrowUpDownIcon, CheckCircleIcon, TimeIcon, LockIcon, WarningTwoIcon, ViewIcon } from '@chakra-ui/icons';

const Decision2024Instructions = () => {
  return (
    <Card width="100%" maxW="2xl" p={5}>
      <CardBody color="#1f3c60" textAlign="left">
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="center">
                <Heading size="sm">What is Bets2024?</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text mb={3}>
                Bets2024 is a platform for trading <Link color='blue.400' href="https://ethereum.org/en/what-is-ethereum" isExternal> Ethereum  <ExternalLinkIcon /></Link> on the candidates of the 2024 US presidential election.
              </Text>
              <Divider my={3} />
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={ArrowUpDownIcon} color="green.500" />
                  <b>Trading Dynamics:</b> Trading stakes on candidates or their parties, should another candidate win. Sales incur a small 2% fee.
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  <b>Instant Transactions:</b> Buy and sell your stakes instantly. No buyer needed when you want to sell, anyone may sell their shares at current market rates.
                </ListItem>
                <ListItem>
                  <ListIcon as={TimeIcon} color="cyan.500" />
                  <b>Election Outcome:</b> Rewards for the winning candidate's stakes are distributed post-election, minus a 10% fee.
                </ListItem>
                <ListItem>
                  <ListIcon as={LockIcon} color="teal.500" />
                  <b>Getting Started:</b> Connect your Ethereum wallet to begin trading. All transactions are secure and smart contract-driven.
                </ListItem>
                <ListItem>
                  <ListIcon as={WarningTwoIcon} color="yellow.500" />
                  <b>Market Adjustment:</b> The market adjusts immediately after you purchase shares, impacting the initial value.
                </ListItem>
                <ListItem>
                  <ListIcon as={ViewIcon} color="pink.500" />
                  <b>Transparency:</b> <Link color='blue.400' href="https://etherscan.io/address/0xAc11B7918d193D3cd0F1E86E2B437aD663793FB9#code" isExternal> Contract source code and transactions are publicly available on Etherscan. <ExternalLinkIcon />
                  </Link>
                </ListItem>
              </List>
              <Divider my={3} />
              <Text mt={4}>
                No need to be a blockchain expert - Bets2024 is designed for ease of use, making trading accessible, transparent, and secure for everyone.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          {window.location.hostname === "sepolia.bets2024.com" && (
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="center">
                  <Heading size="sm">Seplia Testnet?</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
                <AccordionPanel pb={4}>
                  <Heading size="sm" mb={3}>Try for free with Sepolia Testnet Ethereum!</Heading>
                  <OrderedList mb={3} pl={5}>
                    <ListItem mb={2}>Click Connect Wallet and create a metamask wallet.</ListItem>
                    <ListItem mb={2}>Copy the SepliaETH address and request free Ethereum from a
                      <Link color='teal.600'  isExternal href="https://sepolia-faucet.pk910.de/">Sepolia Faucet. <ExternalLinkIcon /></Link>
                    </ListItem>
                    <ListItem mb={2}>Buy shares for a candidate. As other people place orders, the value of your shares will change.</ListItem>
                    <ListItem mb={2}>When you are ready to sell, click the Sell button and confirm the transaction.</ListItem>
                    <ListItem mb={2}>When the election is called by AP News, the payout process will occur..</ListItem>
                  </OrderedList>
                  <Text mb={3}><Link color='blue.600' href="https://sepolia.etherscan.io/address/0x98290F832957671DeF2cC04f18F024b90e33a186#code" isExternal>The election market smart contract code for Sepolia is publicly available on Etherscan. <ExternalLinkIcon /></Link></Text>
                </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </CardBody>
    </Card>
  );
};

export default Decision2024Instructions;
