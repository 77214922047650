
import React from 'react';
import { Card, Text, useBreakpointValue, Box } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from 'moment';
import web3 from 'web3';

const GET_ELECTION_DATA = gql`
  query GetElectionData {
    transacteds {
      amount
      candidate
      operation
      user
      blockTimestamp
      trumpTotalStakeAmount
      bidenTotalStakeAmount
      trumpOdds
      bidenOdds
    }
  }
`;

const GET_SEPOLIA_ELECTION_DATA = gql`
  query GetElectionData {
    transacteds {
      amount
      candidate
      operation
      user
      transaction {
        blockTimestamp
      }
      trumpTotalStakeAmount
      bidenTotalStakeAmount
      trumpOdds
      bidenOdds
    }
  }
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{moment(label).format('M/D HH:mm:ss')}</p>
        {payload.map((item, index) => (
          <p key={index} className="intro">{`${item.dataKey}: ${parseFloat(item.value).toFixed(2)} ${item.dataKey === 'odds' ? '%' : 'ETH'}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

const ChartRenderer = ({ data, yTickFormatter, legendContent, lines }) => (
  <Box width="100%">
    <LineChart width={useBreakpointValue({ base: 340, sm: 440, md: 640 })} height={useBreakpointValue({ base: 220, sm: 300, md: 350 })} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" scale="time" type="number" domain={['auto', 'auto']} tickFormatter={(unixTime) => moment(unixTime).format('M/D')} />
      <YAxis tickFormatter={yTickFormatter} />
      <Tooltip content={<CustomTooltip />} />
      <Legend content={legendContent} />
      {lines.map((line, index) => <Line key={index} {...line} />)}
    </LineChart>
  </Box>
);

const Decision2024Graph = () => {
  const isSepolia = window.location.hostname === 'sepolia.bets2024.com';
  let query = isSepolia ? GET_SEPOLIA_ELECTION_DATA : GET_ELECTION_DATA;

  const { data, loading, error } = useQuery(query);
  if (error) {
    console.log("Query: ", query)
    console.error("GraphQL Error: ", error);
  }
  console.log("data: ", data);

  let transformedData = data && data.transacteds ? data.transacteds.map(item => ({
    time: isSepolia ? item.transaction.blockTimestamp : item.blockTimestamp * 1000,
    Biden: Number(web3.utils.fromWei(item.bidenTotalStakeAmount.toString(), 'ether')),
    Trump: Number(web3.utils.fromWei(item.trumpTotalStakeAmount.toString(), 'ether')),
    odds: Number(web3.utils.fromWei(item.bidenOdds.toString(), 'ether')) / (Number(web3.utils.fromWei(item.bidenOdds.toString(), 'ether')) + Number(web3.utils.fromWei(item.trumpOdds.toString(), 'ether')))
  })).sort((a, b) => a.time - b.time) : [];  

  return (
    <Box>
      <Card p={4} width="100%" mb={2}>
        <ChartRenderer
          data={transformedData}
          yTickFormatter={(value) => `${value} ETH`}
          legendContent={() => <Text textAlign="center" fontSize="sm" color="#1f3c60">Stake totals per candidate over time</Text>}
          lines={[
            { type: "monotone", dataKey: "Biden", stroke: "#3182CE" },
            { type: "monotone", dataKey: "Trump", stroke: "#E53E3E" },
          ]}
        />
      </Card>
      <Card p={4}>
        <ChartRenderer
          data={transformedData}
          yTickFormatter={(value) => `${(value * 100).toFixed(2)}%`}
          legendContent={() => <Text textAlign="center" fontSize="sm" color="#1f3c60">Biden to Trump stake percentage over time</Text>}
          lines={[{ type: "monotone", dataKey: "odds", stroke: "#1f3c60" }]}
        />
      </Card>
    </Box>
  );
};


export default Decision2024Graph;
