import React from 'react';
import { Button, Card, HStack, Heading } from '@chakra-ui/react';
import ElectionMarket from './ElectionMarket';
import { provider } from './useGameData';
const ethers = require("ethers");
export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const OwnerActions = ({ account }) => {
  const declareWinner = async (candidateEnumValue) => {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, ElectionMarket, signer);
    const tx = await contract.declareWinner(candidateEnumValue);
    const receipt = await tx.wait();
    if (receipt.status !== 1) throw new Error("Transaction failed");
  };

  return (
    <Card width="100%" boxShadow="md" borderRadius="md" p={4}>
      <Heading size="md" align="center" pb={4}>Owner Actions</Heading>
      <HStack>
        <Button width="50%" colorScheme="blue" onClick={() => declareWinner(1)}>
          Declare Biden Winner
        </Button>
        <Button width="50%" colorScheme="red" onClick={() => declareWinner(0)}>
          Declare Trump Winner
        </Button>
      </HStack>
    </Card>
  );
};

export default OwnerActions;
