import { useState, useEffect, useCallback } from 'react';
import { useAccount } from 'wagmi';
import { isAddress } from 'web3-utils';
import ElectionMarket from './ElectionMarket';
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($user: String!) {
    transacteds(where: { user: $user }) {
      amount
      operation
      candidate
    }
  }
`;

const ethers = require("ethers");
const infuraApiKey = "09a3e3f2c2204c3eb4fe1913fa50822d";

export let contractAddress = "0xAc11B7918d193D3cd0F1E86E2B437aD663793FB9";
export let ethereumNetwork = "mainnet";
const isSepolia = window.location.hostname === 'sepolia.bets2024.com';
if(isSepolia) {
  contractAddress = "0x98290F832957671DeF2cC04f18F024b90e33a186";
  ethereumNetwork = "sepolia";
}

const infuraUrl = `https://${ethereumNetwork}.infura.io/v3/${infuraApiKey}`;

export function getProvider() {
  if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
    if (window.ethereum.selectedAddress) {
      return new ethers.BrowserProvider(window.ethereum);
    }
  }
  console.log('Falling back to Infura');
  return new ethers.JsonRpcProvider(infuraUrl);
}

export const provider = getProvider();

export function useGameData(addAlert, setIsLoading) {
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected });
    },
  });

  const [candidatesData, setCandidatesData] = useState(null);
  const [userTransactionsData, setUserTranscations] = useState(null);
  const [yourGainLoss, setYourGainLoss] = useState({ Trump: 0, Biden: 0 });
  
  const { data: userTransactionsResponse } = useQuery(GET_USER_TRANSACTIONS, {
    variables: { user: account?.address },
    skip: !account?.address,
  });
  
  useEffect(() => {
    if (userTransactionsResponse) {
      setUserTranscations(userTransactionsResponse.transacteds);
    }
  }, [userTransactionsResponse]);
  
  useEffect(() => {
    if (userTransactionsData && candidatesData) {
      let totalBuyOrderAmount = { Trump: 0, Biden: 0 };
      let totalSellOrderAmount = { Trump: 0, Biden: 0 };
      
      userTransactionsData.forEach(transaction => {
        if (transaction.operation === 'Buy') {
          totalBuyOrderAmount[transaction.candidate] += parseFloat(transaction.amount);
        } else if (transaction.operation === 'Sell') {
          totalSellOrderAmount[transaction.candidate] += parseFloat(transaction.amount);
        }
      });
      
      const userStakeTrump = parseFloat(candidatesData?.Trump.userStake || 0);
      const userStakeBiden = parseFloat(candidatesData?.Biden.userStake || 0);
      
      setYourGainLoss({
        Trump: userStakeTrump - totalBuyOrderAmount.Trump + totalSellOrderAmount.Trump,
        Biden: userStakeBiden - totalBuyOrderAmount.Biden + totalSellOrderAmount.Biden
      });
    }
  }, [userTransactionsData, candidatesData]);  
  
  const fetchGameData = useCallback(async () => {
    setIsLoading(true);
    let provider = getProvider();
    // console.log("contract address", contractAddress);
    // console.log("election market", ElectionMarket);
    // console.log("provider", provider);
    let contract = new ethers.Contract(contractAddress, ElectionMarket, provider);

    const accountAddress = (account && isAddress(account.address)) ? account.address : '0x0000000000000000000000000000000000000000';
    try {
      const {
        winner,
        totalStake,
        trumpTotalStakeAmount,
        trumpStakers,
        bidenTotalStakeAmount,
        bidenStakers,
        userStakeTrump,
        userStakeBiden,
        trumpOdds,
        bidenOdds
      } = await contract?.getStateInformation(accountAddress);

      const trumpRatio = totalStake === 0 ? 0 : trumpTotalStakeAmount / totalStake;
      const bidenRatio = totalStake === 0 ? 0 : bidenTotalStakeAmount / totalStake;

      const gameData = {
        winner,
        totalStake,
        Trump: {
          totalStakeAmount: trumpTotalStakeAmount,
          stakers: trumpStakers,
          userStake: userStakeTrump,
          odds: trumpOdds,
          ratio: trumpRatio
        },
        Biden: {
          totalStakeAmount: bidenTotalStakeAmount,
          stakers: bidenStakers,
          userStake: userStakeBiden,
          odds: bidenOdds,
          ratio: bidenRatio
        }
      };

      console.log('Fetched game data', gameData);

      setCandidatesData(gameData);
    } catch (err) {
      console.error('Error fetching game data:', err);
      addAlert({
        status: 'error',
        title: 'An error occured while fetching data',
        description: 'Connect wallet and try again.'
      });
    } finally {
      setIsLoading(false);
    }
  }, [addAlert, setIsLoading, userTransactionsData]);

  useEffect(() => {
    const provider = getProvider();
    const contract = new ethers.Contract(contractAddress, ElectionMarket, provider);
    fetchGameData();
    contract.on("Transacted", fetchGameData);
    contract.on("WinnerDeclared", (candidate) => {
      addAlert({
        status: 'success',
        title: `Winner: ${candidate}`,
        description: 'The election winner has been declared!',
      });
      fetchGameData();
    });
    return () => {
      contract.removeAllListeners();
    };
  }, [account?.address, fetchGameData, addAlert]);

  return { candidatesData, account: account?.address, fetchGameData, yourGainLoss };
}
