import React, { useCallback, useState } from 'react';
import { FormControl, FormLabel, Button, useBreakpointValue, Stat, StatLabel, StatNumber, Heading, Card, CardBody, VStack, Stack, Text, Input, StatHelpText, HStack, Divider, Image } from '@chakra-ui/react';
import ElectionMarket from './ElectionMarket';
import { useGameData, contractAddress, provider } from './useGameData';

const ethers = require("ethers");

const getColorScheme = candidate => (candidate === 'Trump' ? 'red' : 'blue');

const ColoredCandidateName = ({ candidate }) => <Text as="span" color={`${getColorScheme(candidate)}.500`}>{candidate}</Text>;

const CandidateOddsInfo = ({ candidate, candidateData, userStake, yourGainLoss }) => {
  if (!candidateData || !candidate) return null;
  const oddsPercent = Number(candidateData.odds);
  let denominator = oddsPercent;
  let numerator = 100 - oddsPercent;

  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  let greatestCommonDivisor = gcd(numerator, denominator);

  while (denominator / greatestCommonDivisor > 5) {
    denominator /= 2;
    numerator /= 2;
    greatestCommonDivisor = gcd(numerator, denominator);
  }

  const fractionalOdds = denominator !== 0 ? `${Math.floor(numerator / greatestCommonDivisor)}:${Math.floor(denominator / greatestCommonDivisor)}` : '0:0';
  const totalStakeAmount = ethers.formatEther(candidateData.totalStakeAmount);

  const changeAmount = ethers.formatEther(yourGainLoss[candidate].toString());

  const direction = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <Card width="100%">
      <CardBody display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mb={6} display="flex" alignItems="center" justifyContent="center">
          <Image src={candidate === 'Trump' ? "/RepublicanLogo.svg" : "/DemocraticLogo.svg"} pr={3} height="44px" mt="5px" />
          <ColoredCandidateName candidate={candidate} />
        </Heading>
        <Stack
          align="center"
          direction={direction}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Stat align="center" maxW="120px" width="100%">
            <StatLabel>Current total</StatLabel>
            <StatNumber>{totalStakeAmount}</StatNumber>
            <StatHelpText>ethereum</StatHelpText>
          </Stat>
          <Stat align="center" maxW="120px" width="100%">
            <StatLabel>Approximate odds</StatLabel>
            <StatNumber>{fractionalOdds}</StatNumber>
            <StatHelpText>{oddsPercent.toFixed(2)}%</StatHelpText>
          </Stat>
        </Stack>
        <Divider mt={4} mb={4} width="100%" />
        <Stack
          align="center"
          direction={direction}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Stat align="center" maxW="120px" width="100%">
            <StatLabel>
              Your current <ColoredCandidateName candidate={candidate} /> value
            </StatLabel>
            <StatNumber>{userStake}</StatNumber>
            <StatHelpText>ethereum</StatHelpText>
          </Stat>
          <Stat align="center" maxW="120px" width="100%">
            <StatLabel>Your <ColoredCandidateName candidate={candidate} /> value change</StatLabel>
            <StatNumber>{changeAmount}</StatNumber>
            <StatHelpText>ethereum</StatHelpText>
          </Stat>
        </Stack>
      </CardBody>
    </Card>
  );
};

const BuySell = ({ candidate, operation, isDisabled, inputValue, makeTransaction, cancelOperation }) => (
  <Card p={4}>
  <FormControl flex={1} as='fieldset'>
    <FormLabel as='legend'>
      {operation} {candidate} stake
    </FormLabel>
    <Stack >
      <Input placeholder={`${operation} amount in Ethereum`} onChange={e => inputValue(e.target.value)} bg="#FFFFFF" />
      <Button onClick={() => makeTransaction(candidate, operation)} isDisabled={isDisabled} colorScheme={getColorScheme(candidate)}>
        Place {operation} order
      </Button>
      <Button onClick={cancelOperation} colorScheme={getColorScheme(candidate)} variant="outline" bg="whitesmoke">
        Cancel
      </Button>
    </Stack>
  </FormControl>
  </Card>
);

const CandidateCard = ({ candidate, isLoading, setEthValue, makeTransaction, gameData, yourGainLoss }) => {
  const [isBuyVisible, setIsBuyVisible] = useState(false);
  const [isSellVisible, setIsSellVisible] = useState(false);
  const toggleBuyVisibility = () => {
    setIsBuyVisible(prev => !prev);
    setEthValue("");
  };
  const toggleSellVisibility = () => {
    setIsSellVisible(prev => !prev);
    setEthValue("");
  };

  const buttonStackDirection = useBreakpointValue({ base: 'column', md: 'row' });
  
  let userStake = ethers.formatEther(gameData?.[candidate]?.userStake || 0);

  return (
    <VStack textAlign="center" width="50%">
      <CandidateOddsInfo candidate={candidate} candidateData={gameData?.[candidate]} userStake={userStake} yourGainLoss={yourGainLoss} />
      <Stack direction={buttonStackDirection} w="100%" align="center" alignItems="flex-start">
        {!isBuyVisible && (
          <Button width='100%' minH="38px" flex={1} onClick={toggleBuyVisibility} colorScheme={getColorScheme(candidate)}>
            Buy
          </Button>
        )}
        {isBuyVisible && (
          <BuySell candidate={candidate} operation='buy' isDisabled={isLoading} inputValue={setEthValue} makeTransaction={makeTransaction} cancelOperation={toggleBuyVisibility} />
        )}
        {!isSellVisible && (
          <Button width='100%' minH="38px" flex={1} onClick={toggleSellVisibility} colorScheme={getColorScheme(candidate)} variant="outline" bg="#FFFFFF">
            Sell
          </Button>
        )}
        {isSellVisible && (
          <BuySell candidate={candidate} operation='sell' isDisabled={isLoading} inputValue={setEthValue} makeTransaction={makeTransaction} cancelOperation={toggleSellVisibility} />
        )}
      </Stack>
    </VStack>
  );
};

const Decision2024 = ({ addAlert }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ethValue, setEthValue] = useState("");
  const { candidatesData: gameData, account, fetchGameData, yourGainLoss } = useGameData(addAlert, setIsLoading);

  const makeTransaction = useCallback(async (candidate, operation) => {
    setIsLoading(true);
    try {
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(contractAddress, ElectionMarket, signer);
      const candidateEnum = candidate === 'Trump' ? 0 : 1;
      const operationEnum = operation === 'buy' ? 0 : 1;
      const transactionTx = await contract.transaction(candidateEnum, operationEnum, { value: ethers.parseEther(ethValue) });
      const receipt = await transactionTx.wait();
      if (receipt.status !== 1) throw new Error("Transaction failed");
    } catch (error) {
      console.error('Failed to execute transaction:', error);
      addAlert({ status: 'error', title: 'Failed to execute transaction', description: 'Check wallet connection?' });
      fetchGameData();
    } finally {
      setIsLoading(false);
    }
  }, [ethValue, gameData, account, contractAddress, addAlert, provider]);

  return (
    <HStack align="start" width="100%">
      <CandidateCard candidate='Biden' isLoading={isLoading} setEthValue={setEthValue} makeTransaction={makeTransaction} gameData={gameData} yourGainLoss={yourGainLoss} />
      <CandidateCard candidate='Trump' isLoading={isLoading} setEthValue={setEthValue} makeTransaction={makeTransaction} gameData={gameData} yourGainLoss={yourGainLoss} />
    </HStack>
  );
};

export default Decision2024;