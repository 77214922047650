import React, { useState, useEffect, createContext, useContext } from 'react';
import { sepolia, mainnet } from "wagmi/chains";
import { WagmiConfig, createConfig } from "wagmi";
import { getDefaultConfig } from "connectkit";
import { createPublicClient, http } from 'viem'

export const BlockchainContext = createContext();

export function BlockchainProvider({ children }) {
  const determineChain = () => {
    const isSepolia = window.location.hostname === 'sepolia.bets2024.com';
    if (isSepolia) {
      return 'sepolia';
    } else {
      return 'mainnet';
    }
  };
  const [chain, setChain] = useState(determineChain());
  const [wagmiConfig, setWagmiConfig] = useState(null);

  useEffect(() => {
    const selectedChain = chain === 'mainnet' ? mainnet : sepolia;
    const newWagmiConfig = createConfig(
      getDefaultConfig({
        appName: "Bets2024",
        appDescription: "A prediction market for the 2024 US Presidential Election.",
        appUrl: "https://bets2024.com",
        appIcon: "https://bets2024.com/logo.png",
        infuraId: "09a3e3f2c2204c3eb4fe1913fa50822d",
        alchemyId: "igGwvfYEjE_9MmOgKDRauqIOlv6LAAqP",
        walletConnectProjectId: "75949b820fc4e395c8fc0455e12da9f2",
        chains: [selectedChain],
        publicClient: createPublicClient({
          chain: selectedChain,
          transport: http()
        })
      })
    );

    setWagmiConfig(newWagmiConfig);
  }, [chain]);

  const value = {
    chain,
    setChain,
  };

  return (
    <BlockchainContext.Provider value={value}>
      {wagmiConfig && (
        <WagmiConfig config={wagmiConfig}>
          {children}
        </WagmiConfig>
      )}
    </BlockchainContext.Provider>
  );
}

export function useBlockchain() {
  return useContext(BlockchainContext);
}