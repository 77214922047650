import React, { useState } from 'react';
import Header from './Header';
import { useAlerts } from './Alerts';
import Decision2024 from './Decision2024';
import Footer from './Footer';
import OwnerActions from './OwnerActions'
import { useGameData } from './useGameData';
import { VStack, Box, Container, Spinner } from '@chakra-ui/react';
import Decision2024Graph from './Decision2024Graph';
import Decision2024Instructions from './Decision2024Instructions';
import { useBlockchain } from './BlockchainContext';

function App() {
  const { chain } = useBlockchain();
  const { AlertsComponent, addAlert } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const { data, account } = useGameData(addAlert, setIsLoading);
  const ownerAddress = "0x1F27c903aaD986e623D5c9B3B179EA3c92aE6eA2"
  const isOwner = account?.address !== undefined && ownerAddress !== undefined && account?.address === ownerAddress;

  const handleChainChange = (newChain) => {
    if (chain === newChain) {
      return;
    }
    const isSepolia = window.location.hostname === 'sepolia.bets2024.com';
    if (newChain === 'mainnet' && !isSepolia && window.location.hostname !== 'bets2024.com') {
      window.location.href = 'https://bets2024.com';
    }
    if (newChain === 'sepolia' && !isSepolia) {
      window.location.href = 'https://sepolia.bets2024.com';
    }
  };

  return (
    <Container
      p={0}
      m={0}
      minHeight="100vh"
      w="100%"
      maxW="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="gainsboro"
    >
      <Box minHeight="100vh" display="flex" flexDirection="column" boxShadow='lg'>
        <Header chain={chain} onChainChange={handleChainChange} />
        <VStack backgroundColor="whitesmoke" w="100%" h="100%" flexGrow={1} p={4}>
          <AlertsComponent />
          <Decision2024Instructions />
          {isLoading ? <Spinner /> : (
          <VStack width="100%">
            <Decision2024 gameData={data} account={account} addAlert={addAlert} />
            <Decision2024Graph />
            {isOwner ? <OwnerActions account={account} /> : null}
          </VStack>
          )}
        </VStack>
        <Footer />
      </Box>
    </Container>
  );
}

export default App;
