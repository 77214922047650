import React from 'react';
import { Flex, Heading, useBreakpointValue, Select, Image, Box } from '@chakra-ui/react';
import { ConnectKitButton } from "connectkit";

const Header = ({ chain, onChainChange }) => {
  const outerDirection = useBreakpointValue({ base: 'column', sm: 'row' });
  const innerDirection = outerDirection === 'row' ? 'row' : 'column';
  const innerJustify = outerDirection === 'row' ? 'space-between' : 'center';
  const paddingRight = innerDirection === 'row' ? 2 : 0;

  return (
    <Box w="100%" mx="auto">
      <Flex direction={outerDirection} backgroundColor="rgba(162, 181, 197, 0.5)" align="center" justify="space-between" p={3}>
        <Flex direction={innerDirection} align="center" justify={innerJustify} color="#1f3c60">
          <Image height="10" src="/logo.svg" pr={paddingRight} pl={3} />
          <Heading size="lg" pr={paddingRight}>Bets2024</Heading>
          <Select value={chain} onChange={(e) => onChainChange(e.target.value)} height="8" variant='filled' m={3}>
            <option value='sepolia'>Sepolia Testnet</option>
            <option value='mainnet'>Ethereum Mainnet</option>
          </Select>
        </Flex>
        <ConnectKitButton showBalance={true} mode="light" />
      </Flex>
    </Box>
  );
};

export default Header;
